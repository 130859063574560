import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Row, Col, Button } from "react-bootstrap"
import styled from "styled-components"

import { CallToAction, Layout, Seo } from "../components"
import Title from "../components/Title"

const query = graphql`
  {
    allDatoCmsAttorney {
      edges {
        node {
          imageProfile {
            alt
            gatsbyImageData
          }
          attorney {
            ... on DatoCmsTitle {
              id
              title
              slug
            }
            ... on DatoCmsTel {
              id
              tel
              ext
            }
          }
          seo {
            description
            image {
              gatsbyImageData
            }
          }
        }
      }
    }

    allDatoCmsPageIntro(filter: { title: { eq: "Attorney Page" } }) {
      edges {
        node {
          id
          info
          title
        }
      }
    }
  }
`

const AttorneyPage = () => {
  const {
    allDatoCmsAttorney: { edges: attorneys },
    allDatoCmsPageIntro: { edges: intro },
  } = useStaticQuery(query)

  const seo = intro[0].node.info

  return (
    <Layout>
      {attorneys.map(({ node }) => {
        const { image } = node.seo
        const seoImage = image.gatsbyImageData.images.src

        return (
          <Seo
            title="Personal Injury and Real Estate Attorneys in St.Alban Vermont"
            description={seo}
            image={seoImage}
          />
        )
      })}

      <div className="section page-header">
        <Container>
          <SectionHeading>
            <Row>
              <Col>
                <Title title="Our Attorneys" />
              </Col>
            </Row>
          </SectionHeading>
        </Container>
      </div>

      <Wrapper className="section">
        <Container>
          <Row>
            <Col>
              {intro.map(({ node }) => {
                return <h3 className="page-intro">{node.info}</h3>
              })}
            </Col>
          </Row>

          <Row>
            {attorneys.map(({ node }) => {
              const image = getImage(node.imageProfile.gatsbyImageData)
              const title = node.attorney[0].title
              const slug = node.attorney[0].slug
              const tel = node.attorney[1].tel
                .toString()
                .replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3")

              const ext = node.attorney[1].ext

              return (
                <Col md={4}>
                  <div className="team-member">
                    <article>
                      <GatsbyImage image={image} alt={node.imageProfile.alt} />
                      <div className="team-desc overlay">
                        <div className="member-info">
                          <h5>{title}</h5>
                          <p>
                            Tel: {tel} ext: <span>{ext}</span>
                          </p>
                        </div>
                        <Link to={`/attorneys/${slug}`}>
                          <Button variant="light">View Profile</Button>
                        </Link>
                      </div>
                    </article>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </Wrapper>

      <CallToAction />
    </Layout>
  )
}

const Wrapper = styled.section`
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
    display: contents;
  }

  .team-member {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: inline-block;

    @media screen and (max-width: 800px) {
      margin-bottom: 2rem;
    }
  }

  .team-member:hover .team-desc.overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 28px 27px 56px;
    height: 100%;
    top: 0;
    margin-top: -0px;
  }

  .team-desc.overlay {
    display: table;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 70px;
    padding: 12px 27px;
    background-color: rgb(182, 157, 116, var(--alpha));
    bottom: 0px;
    top: 100%;
    margin-top: -70px;
    transition: all 0.5s ease;
  }

  .member-info,
  .member-info p {
    color: var(--clr-secondary-1);
  }

  h2.section-heading {
    color: var(--clr-secondary-1);
  }

  .section-heading::after {
    content: "";
    display: block;
    position: relative;
    height: 3px;
    width: 30px;
    background-color: var(--clr-primary-6);
    margin: 40px auto;
  }
`

const SectionHeading = styled.section`
  @media screen and (max-width: 480px) {
    padding: 4rem 0 0 0;
  }

  h2.section-heading {
    text-align: center;
    color: var(--clr-primary-6);
    padding-top: 7rem;

    @media screen and (max-width: 480px) {
      padding-top: 2rem;
    }
  }
`

export default AttorneyPage
